import WiNuxtErrorBoundary from '@/shared/ui/WiNuxtErrorBoundary/index.vue';
import WiNotificationSection from '~/widgets/WiNotificationSection/ui/index.vue';
import WiLoginModal from '~/widgets/WiLoginModal/ui/index.vue';
import WiSupportModal from '~/shared/ui/WiSupportModal/index.vue';
import WiConfirmAndVerifyPhoneModals from '~/shared/ui/WiConfirmAndVerifyPhoneModals/index.vue';
import WiCookieModal from '~/shared/ui/WiCookieModal/index.vue';
import { useMobile } from '~/shared/hooks/useMobile';

export default defineNuxtComponent({
  name: 'MainLayout',

  components: {
    WiNuxtErrorBoundary,
    WiNotificationSection,
    WiLoginModal,
    WiConfirmAndVerifyPhoneModals,
    WiSupportModal,
    WiCookieModal,
  },

  setup() {
    const managementStore = globalComponentManagementStore();

    const { isMobile } = useMobile();

    const onCloseLoginModal = () => {
      managementStore.setIsOpenLoginModal(false);
    };

    return {
      managementStore,
      isMobile,

      onCloseLoginModal,
    };
  },
});
